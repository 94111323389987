
.button-outline-variant(@color) {
  border-width:2px;
  color: @color;
  background-image: none;
  background-color: transparent;
  border-color: desaturate((@color + 30%), 20%);

  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: #fff;
    background-color: @color;
        border-color: @color;
  }

  &:hover,
  &:focus,
  &.focus {
    color: #fff;
    background-color: @color;
        border-color: @color;
  }

  &.disabled,
  &:disabled,
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      border-color: lighten(@color, 20%);
    }
  }
}

// Remove all backgrounds
.btn-primary-outline {
  .button-outline-variant(@btn-primary-bg);
}
.btn-secondary-outline {
  .button-outline-variant(@brand-secondary);
}
.btn-info-outline {
  .button-outline-variant(@btn-info-bg);
}
.btn-success-outline {
  .button-outline-variant(@btn-success-bg);
}
.btn-warning-outline {
  .button-outline-variant(@btn-warning-bg);
}
.btn-danger-outline {
  .button-outline-variant(@btn-danger-bg);
}

.btn-secondary {
  .button-variant(#fff, lighten(desaturate(@brand-secondary, 22%), 8%), @brand-secondary);
}

.btn {
  transition: background .15s, color .15s;
}
