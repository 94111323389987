
.form-control-validation(@text-color: #555; @border-color: #ccc; @background-color: #f5f5f5) {
  // Set the border and box shadow on specific inputs to match
  .form-control {
    &:focus {
      border-color: darken(@border-color, 10%);
      @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 0 1px @border-color;
      .box-shadow(@shadow);
    }
  }
}

.form-control-focus(@color: @input-border-focus) {
  @color-rgba: rgba(red(@color), green(@color), blue(@color), 1);
  &:focus {
    border-color: @color;
    outline: 0;
    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 0 1px @{color-rgba}");
  }
}

.control-label {
  font-weight:500;
}
