@import "bootstrap";
@import "buttons";
@import "forms";
@import "navbar";
@import "utilities-spacing";
@import (less) "../../../bower_components/slick.js/slick/slick.css";
@import "../../../bower_components/seiyria-bootstrap-slider/less/bootstrap-slider";
@import (less) "../../../bower_components/magnific-popup/dist/magnific-popup.css";
@import "slick-theme";

// theme

@grid-float-breakpoint: 850px;

@brand-primary: desaturate(lighten(#0467C5, 10%), 35%);
@brand-secondary: desaturate(lighten(#009404, 9%), 35%);
@brand-light: desaturate(rgba(243,249,254,.97), 10%);
@brand-light-light: (@brand-light + 15%);
@brand-light-shadow:  #ddd;

@border-radius-base: 2px;
@btn-font-weight: 600;

@table-bg-accent: @brand-light;
@table-border-color: (@brand-light - 10%);

@text-color: #606060;

@font-size-small:         ceil((@font-size-base * 0.90));

@dropdown-link-hover-bg: @navbar-default-link-hover-bg;

@jumbotron-bg: #fff;;

@panel-default-heading-bg:  #fff;
@panel-body-padding: 20px;
@panel-primary-border: desaturate((@brand-primary + 90%), 90%);

@list-group-bg: #fff;
@list-group-active-bg: #fff;
@list-group-active-color: @link-color;
@list-group-hover-bg: #fff;
@list-group-link-hover-color: @link-color;

@body-bg: #fff;
@font-size-base:16px;
@font-family-sans-serif: "proxima-nova", helvetica, arial, sans-serif;
@headings-font-family: "franklin-gothic-urw", sans-serif;

// typekit: prevent FLOUT
a, .btn, h1, h2, h3, blockquote, p {
  .wf-loading & {
    visibility:hidden;
  }
  .wf-active &,
  .wf-inactive & {
    visibility:visible;
  }
}


// backward compatibility
#content {
  h1, h2, h3, h4, p, blockquote, .panel {
    max-width:600px;
  }

  .float-left {
    float:left;
  }
}
.float-right {
  float:right;
  margin-left:@grid-gutter-width;
  margin-bottom:@line-height-computed;
  img,
  &img {
    max-width:100%;
    display:inline-block;
  }
  @media (max-width:@grid-float-breakpoint) {
    float:none;
    display:block;
    margin-left:auto;
    margin-right:auto;
  }
}


.container-fluid {
  max-width: @container-lg;
}

// typography
@font-size-h1: 37px;
@font-size-h2: 29px;
@font-size-h3: 20px;
@font-size-h4: 16px;

h1,.h1 {
  margin-top:25px;
  margin-bottom:25px;
  font-weight:900;
  font-style:italic;
  color:@brand-primary;
}

.page-index {
  h1,.h1 {
    margin:0;
  }
}

h2,.h2 {
  margin-top:30px;
  margin-bottom:15px;
  color:@brand-primary;
  font-weight:900;
  font-style:italic;
}

h3,.h3 {
  margin-top:30px;
  margin-bottom:15px;
  color:@brand-primary;
  font-weight:900;
  font-style:italic;
}
h4,.h4 {
  margin-top:30px;
  margin-bottom:10px;
  font-weight:600;
  color:@brand-primary;
}
@media (max-width:@screen-sm-max) {
  h1,.h1 {
    font-size:30px;
  }
}

strong,
.alert .alert-link {
  font-weight:600;
}

blockquote {
  font-style:italic;
  font-family: "franklin-gothic-urw", sans-serif;
  font-weight:900;
  font-size:18px;
  color:@brand-secondary;
  color:fadeout(@brand-secondary, 25%);
  border-left-color:fadeout(@brand-secondary, 75%);
}

.banner {
  position:relative;

  img {
    display:block;
    height:auto;
    width:100%;
    max-width:100%;
  }
}

.panel-default {
  box-shadow:0 1 3px rgba(0,0,0,.2);
}
.panel-primary .list-group-item {
  border-color:@panel-primary-border;
}
.panel-success .list-group-item {
  border-color:@panel-success-border;
}
.panel-danger .list-group-item {
  border-color:@panel-danger-border;
}
.list-group-item > ul {
  margin-bottom:0;
}
a.list-group-item {
  color:@link-color;
}
a.list-group-item.active {
  font-weight:bold;
  border-left:5px solid @brand-secondary !important;
  color:@brand-secondary;
  background-color:desaturate(lighten(@brand-secondary, 58%), 20%);
  &:hover {
    border-color:inherit;
  }
}
.panel-primary {
  a.list-group-item.active:hover {
    color:@brand-secondary;
    border-color:@panel-primary-border;
    background-color:desaturate(lighten(@brand-secondary, 57%), 20%);
  }
}

h2:first-child,
h3:first-child,
h4:first-child {
  margin-top:0;
}

footer {
  background-color: @brand-light;
  margin-top:40px;
  padding:40px 0;
  .small;
  .text-muted;

  .footer-title {
    font-weight:600;
  }
  .arboquebec-css {
    font-size:1.4em;
  }
}

.arboquebec-css {
  font-style:italic;
  font-family: "franklin-gothic-urw", sans-serif;
  font-weight: 900;
  &.arbo {
    color:@brand-secondary !important;
  }
  &.quebec {
    color:@brand-primary;
  }
}

.text-secondary {
  color:screen(@brand-secondary, #222);
}

/* tables services */
.table_service {background-color: #FFF; padding:0;}
.table_service table {border-spacing:2px; border-collapse:separate;}
.table_service td {padding:2px 5px; vertical-align:middle;}
#logo_table_service {width: 30px; height: 42px;}
.td_service_titre {color: #000;font-weight: 600;}
.td_contact_type, .td_service {background-color: #ABDCF4; font-weight: 600;}
.td_service_titre_mois {text-align: center; color: #000; .small;}
.td_service_mois {background-color: #ABDCF4; text-align: center; color: #000; .small;}
.td_service_detail {background-color: #E4F3FC;}


.slider.slider-horizontal {
  width:285px;
  margin:10px;
  @media (max-width:@grid-float-breakpoint) {
    width:230px;

  }
}

.emergency-panel,
.availability-panel {
  .panel-body {
    padding:12px 15px;
  }
  .checkbox,
  .form-group {
    margin:0;
  }
  .panel {
    margin-bottom:7px;
  }
}

.breadcrumb {
  margin-top:@line-height-computed;
  margin-bottom:0;
  background-color:@brand-light;
}
.social-icon {
  display:inline-block;
  width:30px;
  img {
    display:inline-block;
    max-width:100%;
  }
}

.social-widgets > * {
  vertical-align:top !important;
  line-height:1;
}

.btn-wrap {
  white-space:normal;
}
.index-video {
  border:2px solid @brand-secondary;
  border-radius:@border-radius-base;
}

.article-lead-img {
  margin-bottom:20px;
}


@blog-index-left-side-offset: 120px;

.blog-index {
  .blog-title,
  .blog-description {
    margin-left:auto;
    margin-right:auto;
    max-width:600px;
  }
  .blog-description {
    font-size:20px;
  }
}

.article-list {
  padding-bottom:@blog-index-left-side-offset;
  overflow:hidden;
  position:relative;

  .article-list-row {
    &:extend(.clearfix);
  }

  .panel-title {
    font-size:22px;
    color:@brand-primary;
    margin-bottom:5px;
  }

  article {
    margin-bottom:20px;
    margin-left:auto;
    margin-right:auto;
    max-width:600px;
  }

  .left-side {
    position:relative;
  }

}

.article-img {
  padding-bottom:1rem;

  @media (min-width:900px){
    &.align-right {
      float:right;
      padding-left:1rem;
    }
    &.align-left {
      float:left;
      padding-right:1rem;
    }
  }
  &.align-gallery {
    float:left;
    padding:10px 0 10px 20px;
  }

  > a, img {
    display:block;
    max-width:100%;
    height:auto;
  }
}



@media (min-width:800px) {
  .blog-index {
    .blog-title,
    .blog-description {
      margin-left:0;
      margin-right:0;
      width:calc(~"50% - 20px");
    }
  }
  .article-list {
    top:-(@blog-index-left-side-offset - 30px);

    article {
      margin-bottom:20px;
      width:calc(~"50% - 20px");
    }

    .left-side {
      float:left;
      clear:left;
      top:@blog-index-left-side-offset;
      margin-right:20px;

    }
    .right-side {
      margin-left:20px;
      float:right;
      clear:right;
    }
  }
}

.alert-error {
  .alert-danger;
}
