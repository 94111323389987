.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    z-index: 1000; /* ie9 */
    top: 50%;

    display: block;

    width: 60px;
    height: 60px;
    margin-top: -30px;
    padding: 0;
    border:none;

    cursor: pointer;

    color: #fff;
    text-shadow:1px 1px 1px #333;
    opacity:.65;
    outline: none;
    background: transparent;
}
.slick-next:before,
.slick-prev:before {
  font-size:70px;
  font-weight:700;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    opacity:1;
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev
{
    left: 25px;
}
.slick-prev:before {
  content: '\2039';
}

.slick-next
{
    right:25px;
}
.slick-next:before {
  content: '\203a';
}

/* Dots */
.slick-slider
{
  margin-bottom: 20px;

  @media (min-width:1200px) {
    margin-bottom: 40px;
  }
}

.slick-dots
{
    position: absolute;
    z-index: 1000; /* ie9 */
    bottom: 0;

    display: block;

    width: 100%;
    padding: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 3px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    opacity:.65;
    display: block;
    font-size: 0;
    line-height: 0;
    border-radius:50%;
    width: 3px;
    height: 3px;
    padding:3px;
    cursor: pointer;
    color: transparent;
    border: 2px solid #fff;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
    background-color:#fff;
    opacity:1;
}
.slick-dots li.slick-active button
{
    background-color:#fff;
    border-width:3px;
}


