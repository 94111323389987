@navbar-height: 50px;
@navbar-height-md: 63px;
@navbar-default-bg: #fBfcfe;
@navbar-default-border:#ddd;
@navbar-default-toggle-border-color: transparent;
@navbar-default-toggle-icon-bar-bg: @brand-primary;
@navbar-default-toggle-hover-bg: transparent;
@navbar-default-link-color: @link-color;
@navbar-default-link-hover-color: @link-color;
@navbar-default-link-hover-bg: (@navbar-default-bg - 5);
@navbar-default-link-active-color: #fff;
@navbar-default-link-active-bg: @brand-primary;

.navbar-vertical-align-md(@element-height) {
  margin-top: ((@navbar-height-md - @element-height) / 2);
  margin-bottom: ((@navbar-height-md - @element-height) / 2);
}

.navbar-arbo {
  .navbar-brand {
    width:180px;
    padding-top:11px;
  }

  .navbar-toggle:not(.collapsed) {
    background-color:@brand-primary;
    border-radius:50%;
    padding: 13px 10px;
    .navbar-vertical-align(42px);
    .icon-bar {
      background-color: #fff;
    }
  }

  .navbar-nav {
    margin-bottom:0;
    margin-top:0;
    border-top:1px solid @brand-light-shadow;
    > li:last-of-type a {
      border-bottom:none;
    }
  }
  .navbar-nav,
  .open .dropdown-menu {
    > li > a {
      color:@navbar-default-link-color;
      padding-left:25px;
      padding-right:25px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight:600;
      border-bottom:1px solid @brand-light-shadow;
      border-top:1px solid @brand-light-light;
      transition: background .15s, color .15s, border-color .15s;
    }
  }
  .open .dropdown-menu {
    padding-top:0;
    padding-bottom:0;
    border-bottom:none;
    min-width:15em;
    background-color:@navbar-default-bg;
    > li > a {
      padding-left:35px;
    }
  }

  @media (min-width: @grid-float-breakpoint) {
    .navbar-nav {
      border-left:1px solid @brand-light-shadow;
      border-right:1px solid @brand-light-light;
      border-top:none;
      > li > a {
        padding-left:15px;
        padding-right:15px;
        padding-top:    @navbar-padding-vertical;
        padding-bottom: @navbar-padding-vertical;
        border-right:1px solid @brand-light-shadow;
        border-left:1px solid @brand-light-light;
        border-bottom:none;
        border-top:none;
      }
    }

    .open .dropdown-menu {
      > li > a {
        padding-left:25px;
      }
    }
    .navbar-right {
      margin-right:1px;
    }

    .btn-estimate {
      // reset .btn-block
      display:inline-block;
      width:auto;
      .btn-sm;
      margin-top:8px !important;
    }
  }

  @media (min-width: (@grid-float-breakpoint + 80px)) {
    .navbar-nav {
      margin-left:10px;
      border-left:1px solid @brand-light-shadow;
      border-right:1px solid @brand-light-light;
      > li > a {
        padding-left:20px;
        padding-right:20px;
      }
    }
  }

  @media (min-width: (@screen-md-min + 100px)) {
    height:@navbar-height-md;

    .navbar-brand {
      width:248px;
      padding-top:10px;
      height:@navbar-height-md;
    }
    .navbar-nav {
      margin-left:40px;
       > li > a {
        @font-size:16px;
        @vertical-padding: ((@navbar-height-md - @font-size) / 2);
        font-size:@font-size;
        line-height:@font-size;
        padding-left:25px;
        padding-right:25px;
        padding-top: @vertical-padding;
        padding-bottom: (@vertical-padding - 1px);
      }
    }
    .navbar-btn.btn {
      .navbar-vertical-align-md(@input-height-base);
    }
    .navbar-btn.btn-sm {
      .navbar-vertical-align-md(@input-height-small);
    }
    .btn-estimate {
      .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
       margin-top:12px !important;
    }
  }
}

.navbar-fixed-top {
  box-shadow:0 0 0 5px rgba(255,255,255,.25), 0 0 5px rgba(0,25,50,.25);
}

.navbar-fixed-top + main {
  margin-top:@navbar-height;
  @media (min-width:@screen-md-min) {
    margin-top:@navbar-height-md;
  }
}

